/* Navigation link styles */
nav a {
  color: var(--background);
  margin: 0 0.5rem;
  text-decoration: none;
}

nav a:hover {
  color: var(--accent);
}

.map-container {
  height: 40vh;
  width: 60%;
}

@media (max-width: 600px) {
  .map-container {
    width: 95%;
    height: 40vh;
  }
}

.full-title {
  display: none;
}

.short-title {
  display: block;
}

@media (min-width: 600px) {
  .full-title {
    display: block;
  }

  .short-title {
    display: none;
  }
}

/* Footer styles */
footer {
  background: var(--primary);
  color: var(--background);
  text-align: center;
  padding: 10px 0;
  width: 100%;
  flex-shrink: 0;
}

/* Hero section styles */
.hero {
  text-align: center;
  padding: 1rem;
  background: var(--secondary);
}

.hero h2 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}


/* Button styles */
button {
  background: linear-gradient(45deg, var(--secondary), var(--accent));
  color: var(--background);
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  border-radius: 50px; /* Rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

/* Add a hover effect */
button:hover {
  background: linear-gradient(45deg, var(--accent), var(--secondary));
  transform: scale(1.01); /* Slightly enlarge the button */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* More pronounced shadow */
}

/* Add a focus effect for accessibility */
button:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--accent), 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Add an active effect */
button:active {
  transform: scale(0.98); /* Slightly shrink the button */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Less pronounced shadow */
}

/* Gallery styles */
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.gallery img {
  width: 100%;
  max-width: 300px;
  border: 2px solid #ddd;
  border-radius: 4px;
}

/* App component styles */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background);
}

main {
  flex: 1;
  background-color: var(--background);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--background);
}

.App-link {
  color: var(--secondary);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-section {
  margin-top: 30px;
}

.carousel {
  position: relative;
  width: 80%;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.image-slide img {
  width: auto;
  max-height: 50vh;
  object-fit: cover;
}


.caption {
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding: 20px;
  position: relative;
}

.caption::before {
  content: open-quote;
  font-size: 3rem;
  position: absolute;
  left: -10px;
  top: 5px;
  color: #555;
}

.caption::after {
  content: close-quote;
  font-size: 3rem;
  position: absolute;
  right: -10px;
  bottom: -5px;
  color: #555;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #000;
  cursor: pointer;
  user-select: none;
  z-index: 10;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}
