:root {
  --text: #070a0f;
  --background: #f9fafd;
  --primary: #3a74c5;
  --secondary: #85ace2;
  --accent: #6398e3;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 28px;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
