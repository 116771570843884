/* Header styles */
.header-container {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.logo-style {
  margin-right: 20px;
}

header {
  background: var(--primary);
  color: var(--background);
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Navigation link styles */
nav a {
  color: var(--background);
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

nav a:hover {
  color: var(--accent);
}

/* Title link styles */
.full-title, .short-title {
  text-decoration: none;
  color: inherit; /* Inherit the color from the parent element */
  font-size: 2em; /* Adjust font size as needed */
  margin: 0; /* Remove default margin */
  font-weight: bold;
}

.full-title {
  display: none;
}

.short-title {
  display: block;
}

@media (min-width: 600px) {
  .full-title {
    display: block;
  }

  .short-title {
    display: none;
  }
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  right: 1.3rem;
  position: absolute
}

.bar {
  width: 25px;
  height: 3px;
  background-color: var(--background);
  margin: 4px 0;
  transition: all 0.3s ease;
}

/* Navigation links for larger screens */
.nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Navigation links for smaller screens */
@media (max-width: 600px) {
  .nav-links {
    display: none;
    flex-direction: column;
    align-items: stretch; /* Make items stretch to the same width */
    position: absolute;
    top: 40px; /* Adjust this value to fit below the hamburger menu */
    right: 0;
    left: auto;
    padding: 0;
    margin-top: 60px;
    z-index: 1000;
  }

  .nav-links.open {
    display: flex;
  }

  .hamburger-menu {
    display: flex;
  }

  nav a {
    margin: 0;
    border: 1px solid var(--background);
    background-color: var(--primary);
    text-align: center;
    padding: 10px 15px; /* Consistent padding for each box */
    width: 100%; /* Make the width consistent */
  }
}
